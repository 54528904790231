import cn from 'classnames';

import s from './styles.module.scss';

export enum TabValue {
  ModelSharedGpu = 'Model Shared GPU',
  InstanceTypeGpu = 'Instance Type GPU',
}

type Tab = {
  label: string;
  value: TabValue;
};

const tabs: Tab[] = [
  {
    label: 'Model Shared GPU',
    value: TabValue.ModelSharedGpu,
  },
  {
    label: 'Instance Type GPU',
    value: TabValue.InstanceTypeGpu,
  },
];

type MlInferenceChangerProps = {
  activeTab: TabValue;
  onTabClick: (value: TabValue) => void;
};

function MlInferenceChanger({
  activeTab,
  onTabClick,
}: MlInferenceChangerProps) {
  return (
    <div className={s.periods}>
      <div className={s.periodsButtons}>
        {tabs.map((tab) => (
          <div
            key={tab.value}
            className={cn(s.period, {
              [s.periodActive]: tab.value === activeTab,
            })}
            onClick={() => onTabClick(tab.value)}
          >
            {tab.label}
          </div>
        ))}
      </div>
    </div>
  );
}

export { MlInferenceChanger };

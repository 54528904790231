import { WorkingHoursSpecification } from 'src/components/Calculator/types/evo';
import { PlanEvoMlInferenceInstanceTypeGpu } from 'src/components/TariffPlans/types';

import { price } from './data';

const evoMlInferenceInstanceTypeGpu1: PlanEvoMlInferenceInstanceTypeGpu = {
  planId: '1 x NVIDIA® V100 NVLink 4 vCPU 64 ГБ RAM',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionMlInference',
  description: 'Подходит для инференса cv-моделей, embedder, reranker',
  data: {
    mlInferenceLaunchMode: 'instance-type-gpu',
    mlInferenceConfigurationType: 'shared-ready',
    mlInferenceConfiguration: 'qwen-qwen-2.5',
    mlInferenceGpuMemory: 4,
    mlInferenceRequestsCount: 10,
    mlInferenceCacheVolume: 5,
    mlInferenceWorkingHours: 1,
    mlInferenceWorkingHoursSpecification: WorkingHoursSpecification.Month,
    productQuantity: 1,
    mlInferenceGpu: 'nvidia-v100-nvlink',
    mlInferenceGpuInstanceType: 'nvidia-v100-nvlink-1gpu',
  },
};

const evoMlInferenceInstanceTypeGpu2: PlanEvoMlInferenceInstanceTypeGpu = {
  planId: '1 x NVIDIA® H100 PCIe 20 vCPU 125 ГБ RAM',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionMlInference',
  description: 'Подходит для инференса моделей с 7B-14B kv-кэшом',
  data: {
    mlInferenceLaunchMode: 'instance-type-gpu',
    mlInferenceConfigurationType: 'shared-ready',
    mlInferenceConfiguration: 'qwen-qwen-2.5',
    mlInferenceGpuMemory: 4,
    mlInferenceRequestsCount: 7,
    mlInferenceCacheVolume: 30,
    mlInferenceWorkingHours: 1,
    mlInferenceWorkingHoursSpecification: WorkingHoursSpecification.Month,
    productQuantity: 1,
    mlInferenceGpu: 'nvidia-h100-pcie',
    mlInferenceGpuInstanceType: 'nvidia-h100-pcie-1gpu',
  },
};

const evoMlInferenceInstanceTypeGpu3: PlanEvoMlInferenceInstanceTypeGpu = {
  planId: '2 x NVIDIA® H100 NVLink 40 vCPU 380 ГБ RAM',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionMlInference',
  description: 'Подходит для больших языковых моделей с 32B с kv-кэшом',
  data: {
    mlInferenceLaunchMode: 'instance-type-gpu',
    mlInferenceConfigurationType: 'shared-ready',
    mlInferenceConfiguration: 'qwen-qwen-2.5',
    mlInferenceGpuMemory: 4,
    mlInferenceRequestsCount: 5,
    mlInferenceCacheVolume: 80,
    mlInferenceWorkingHours: 1,
    mlInferenceWorkingHoursSpecification: WorkingHoursSpecification.Month,
    productQuantity: 1,
    mlInferenceGpu: 'nvidia-h100-nvlink',
    mlInferenceGpuInstanceType: 'nvidia-h100-nvlink-2gpu',
  },
};

export {
  evoMlInferenceInstanceTypeGpu1,
  evoMlInferenceInstanceTypeGpu2,
  evoMlInferenceInstanceTypeGpu3,
};

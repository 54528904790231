import { WorkingHoursSpecification } from 'src/components/Calculator/types/evo';
import { PlanEvoMlInferenceModelSharedGpu } from 'src/components/TariffPlans/types';

import { price } from './data';

const evoMlInferenceModelSharedGpu1: PlanEvoMlInferenceModelSharedGpu = {
  planId: '4Gpu1Cache10MRequests',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionMlInference',
  description: 'Подходит для модели google-bert/bert-base-uncased',
  data: {
    mlInferenceLaunchMode: 'model-shared-gpu',
    mlInferenceConfigurationType: 'custom',
    mlInferenceConfiguration: 'google',
    mlInferenceGpuMemory: 4,
    mlInferenceRequestsCount: 10,
    mlInferenceCacheVolume: 1,
    mlInferenceWorkingHours: 1,
    mlInferenceWorkingHoursSpecification: WorkingHoursSpecification.Month,
    productQuantity: 1,
    mlInferenceGpu: 'nvidia-h100-pcie',
    mlInferenceGpuInstanceType: 'nvidia-h100-pcie-1gpu',
  },
};

const evoMlInferenceModelSharedGpu2: PlanEvoMlInferenceModelSharedGpu = {
  planId: '4Gpu1Cache15MRequests',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionMlInference',
  description: 'Подходит для модели BAAI/bge-reranker-v2-m3',
  data: {
    mlInferenceLaunchMode: 'model-shared-gpu',
    mlInferenceConfigurationType: 'custom',
    mlInferenceConfiguration: 'google',
    mlInferenceGpuMemory: 4,
    mlInferenceRequestsCount: 15,
    mlInferenceCacheVolume: 1,
    mlInferenceWorkingHours: 1,
    mlInferenceWorkingHoursSpecification: WorkingHoursSpecification.Month,
    productQuantity: 1,
    mlInferenceGpu: 'nvidia-v100-nvlink',
    mlInferenceGpuInstanceType: 'nvidia-h100-pcie-1gpu',
  },
};

const evoMlInferenceModelSharedGpu3: PlanEvoMlInferenceModelSharedGpu = {
  planId: '80Gpu90Cache5MRequests',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionMlInference',
  description: 'Подходит для модели Qwen/Qwen2.5-Coder-32B-Instruct',
  data: {
    mlInferenceLaunchMode: 'model-shared-gpu',
    mlInferenceConfigurationType: 'custom',
    mlInferenceConfiguration: 'google',
    mlInferenceGpuMemory: 80,
    mlInferenceRequestsCount: 5,
    mlInferenceCacheVolume: 90,
    mlInferenceWorkingHours: 1,
    mlInferenceWorkingHoursSpecification: WorkingHoursSpecification.Month,
    productQuantity: 1,
    mlInferenceGpu: 'nvidia-h100-pcie',
    mlInferenceGpuInstanceType: 'nvidia-h100-pcie-1gpu',
  },
};

export {
  evoMlInferenceModelSharedGpu1,
  evoMlInferenceModelSharedGpu2,
  evoMlInferenceModelSharedGpu3,
};

import {
  PlanEvoBareMetal,
  PlanEvoCloudServer,
  PlanEvoCloudServerGpu,
  PlanEvoContainerApps,
  PlanEvoKuber,
  PlanEvoKuberLandingPage,
  PlanEvoMlInferenceInstanceTypeGpu,
  PlanEvoMlInferenceModelSharedGpu,
  PlanEvoPostgreSql,
  PlanEvoStorage,
  Plans,
  PlansMainPage,
  PlansProducts,
  StateType,
  TariffPlanCards,
  TariffPlansInitialData,
} from 'src/components/TariffPlans/types';

import { evoBareMetal1, evoBareMetal2, evoBareMetal3 } from './evoBareMetal';
import {
  evoCloudServer1,
  evoCloudServer2,
  evoCloudServer3,
} from './evoCloudServer';
import {
  evoCloudServerGpu1,
  evoCloudServerGpu2,
  evoCloudServerGpu3,
} from './evoCloudServerGpu';
import {
  evoContainerApps1,
  evoContainerApps2,
  evoContainerApps3,
} from './evoContainerApps';
import { evoKuber1, evoKuber2, evoKuber3 } from './evoKuber';
import {
  evoKuber1 as evoKuber1Lp,
  evoKuber2 as evoKuber2Lp,
  evoKuber3 as evoKuber3Lp,
  evoKuber4 as evoKuber4Lp,
} from './evoKuberLandingPage';
import {
  evoMlInferenceInstanceTypeGpu1,
  evoMlInferenceInstanceTypeGpu2,
  evoMlInferenceInstanceTypeGpu3,
} from './evoMlInferenceInstanceTypeGpu';
import {
  evoMlInferenceModelSharedGpu1,
  evoMlInferenceModelSharedGpu2,
  evoMlInferenceModelSharedGpu3,
} from './evoMlInferenceModelSharedGpu';
import {
  evoPostgreSql1,
  evoPostgreSql2,
  evoPostgreSql3,
} from './evoPostgreSql';
import { evoStorage1, evoStorage2, evoStorage3 } from './evoStorage';

const evoCloudServerMainPage: TariffPlanCards<PlanEvoCloudServer> = [
  evoCloudServer1,
  evoCloudServer2,
  evoCloudServer3,
];

const evoKuberMainPage: TariffPlanCards<PlanEvoKuber> = [
  evoKuber1,
  evoKuber2,
  evoKuber3,
];

const evoStorageMainPage: TariffPlanCards<PlanEvoStorage> = [
  evoStorage1,
  evoStorage2,
  evoStorage3,
];

const evoBareMetalMainPage: TariffPlanCards<PlanEvoBareMetal> = [
  evoBareMetal1,
  evoBareMetal2,
  evoBareMetal3,
];

const evoCloudServerGpuMainPage: TariffPlanCards<PlanEvoCloudServerGpu> = [
  evoCloudServerGpu1,
  evoCloudServerGpu2,
  evoCloudServerGpu3,
];

const evoPostgreSqlMainPage: TariffPlanCards<PlanEvoPostgreSql> = [
  evoPostgreSql1,
  evoPostgreSql2,
  evoPostgreSql3,
];

const evoContainerAppsMainPage: TariffPlanCards<PlanEvoContainerApps> = [
  evoContainerApps1,
  evoContainerApps2,
  evoContainerApps3,
];

const evoKubernetesLandingPage: PlanEvoKuberLandingPage[] = [
  evoKuber1Lp,
  evoKuber2Lp,
  evoKuber3Lp,
  evoKuber4Lp,
];

const evoMlInferenceInstanceTypesGpu: TariffPlanCards<PlanEvoMlInferenceInstanceTypeGpu> =
  [
    evoMlInferenceInstanceTypeGpu1,
    evoMlInferenceInstanceTypeGpu2,
    evoMlInferenceInstanceTypeGpu3,
  ];

const evoMlInferenceModelShareGpu: TariffPlanCards<PlanEvoMlInferenceModelSharedGpu> =
  [
    evoMlInferenceModelSharedGpu1,
    evoMlInferenceModelSharedGpu2,
    evoMlInferenceModelSharedGpu3,
  ];

const plansMainPage: PlansMainPage = {
  evolutionCloudServer: evoCloudServerMainPage,
  evolutionKubernetes: evoKuberMainPage,
  evolutionStorageS3: evoStorageMainPage,
  evolutionBareMetal: evoBareMetalMainPage,
  evolutionCloudServerGpu: evoCloudServerGpuMainPage,
  evolutionPostgreSql: evoPostgreSqlMainPage,
  evolutionKubernetesLandingPage: evoKubernetesLandingPage,
  evolutionContainerApps: evoContainerAppsMainPage,
  evolutionMlInferenceModelSharedGpu: evoMlInferenceModelShareGpu,
  evolutionMlInferenceInstanceTypeGpu: evoMlInferenceInstanceTypesGpu,
};

const plansProduct: PlansProducts = {
  evolutionCloudServer: evoCloudServerMainPage,
  evolutionKubernetes: evoKuberMainPage,
  evolutionStorageS3: evoStorageMainPage,
  evolutionBareMetal: evoBareMetalMainPage,
  evolutionCloudServerGpu: evoCloudServerGpuMainPage,
  evolutionPostgreSql: evoPostgreSqlMainPage,
  evolutionKubernetesLandingPage: evoKubernetesLandingPage,
  evolutionContainerApps: evoContainerAppsMainPage,
  evolutionMlInferenceModelSharedGpu: evoMlInferenceModelShareGpu,
  evolutionMlInferenceInstanceTypeGpu: evoMlInferenceInstanceTypesGpu,
};

const plans: Plans = {
  mainPage: plansMainPage,
  product: plansProduct,
};

const initialDataMainPage: StateType = {
  tariffPlansType: 'mainPage',
  currentPricePeriod: 'priceMonthNds',
  activePlansSet: 'evoCloudServerMainPage',
  activePlanIndex: 0,
  plans: plans,
};

const initialDataProduct: StateType = {
  tariffPlansType: 'product',
  currentPricePeriod: 'priceMonthNds',
  activePlansSet: 'evoCloudServerMainPage',
  activePlanIndex: 0,
  plans: plans,
};

const tariffPlansInitialData: TariffPlansInitialData = {
  mainPage: initialDataMainPage,
  product: initialDataProduct,
};

const CONSOLE_STATIC_PAGE_LOGIN_DESTINATION_URL =
  'https://console.cloud.ru/static-pages/login-destination';

const CONSOLE_DEV_STATIC_PAGE_LOGIN_DESTINATION_URL =
  'https://console-dev.cp.sbercloud.dev/static-pages/login-destination';

export {
  CONSOLE_DEV_STATIC_PAGE_LOGIN_DESTINATION_URL,
  CONSOLE_STATIC_PAGE_LOGIN_DESTINATION_URL,
  tariffPlansInitialData,
};

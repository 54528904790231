import { MlInferenceGpu } from 'src/components/Calculator/types/evo';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import { useTariffPlansState } from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoMlInferenceModelSharedGpu,
  PlanIdMlInferenceModelSharedGpu,
  PlansSwiperProps,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { TariffSwiper } from '../../components/TariffSwiper';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';
import s from './EvoMlInference.module.scss';

interface PlanCardProps {
  planId: PlanIdMlInferenceModelSharedGpu;
  plan: PlanEvoMlInferenceModelSharedGpu;
  price: number;
  description: string;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
}

const getGpuLabel = (value: MlInferenceGpu) => {
  switch (value) {
    case 'nvidia-h100-pcie':
      return 'NVIDIA® H100 PCIe';
    case 'nvidia-v100-nvlink':
      return 'NVIDIA® V100 NVLink';
    case 'nvidia-h100-nvlink':
      return 'NVIDIA® H100 PCIe';
    default:
      return '';
  }
};

function MlInferenceModelSharedGpuCard({
  planId,
  price,
  plan,
  description,
  onClickOnConnect,
  currentPricePeriod,
}: PlanCardProps) {
  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType:
      ACTIONS.UPD_PLAN_EVOLUTION_ML_INFERENCE_MODEL_SHARED_GPU_PRICE,
    statusActionType:
      ACTIONS.UPD_PLAN_EVOLUTION_ML_INFERENCE_MODEL_SHARED_GPU_STATUS,
    productType: 'evolutionMlInference',
    nestedProductType: 'evolutionMlInferenceModelSharedGpu',
  });

  const characteristics = [
    {
      title: 'Графический процессор',
      value: getGpuLabel(plan.data.mlInferenceGpu),
    },
    {
      title: 'Видеопамять GPU',
      value: `${plan.data.mlInferenceGpuMemory} ГБ`,
    },
    {
      title: 'Запросы к ML-моделям',
      value: `${plan.data.mlInferenceRequestsCount} млн запросов`,
    },
    {
      title: 'Кэш ML-моделей',
      value: `${plan.data.mlInferenceCacheVolume} ГБ`,
    },
    {
      title: 'Время работы',
      value: `${plan.data.mlInferenceWorkingHours} час`,
    },
  ];

  return (
    <PlanCard
      productType="evolutionMlInference"
      planStatus={planState?.planStatus}
      description={description}
      descriptionClassName={s.cardDescription}
      currentPricePeriod={currentPricePeriod}
      price={price}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
    />
  );
}

function MlInferenceModelSharedGpu({ isProductPage }: PlansSwiperProps) {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[
    state.tariffPlansType
  ].evolutionMlInferenceModelSharedGpu.map((plan) => {
    return (
      <MlInferenceModelSharedGpuCard
        key={plan.planId}
        plan={plan}
        planId={plan.planId}
        price={plan.price[state.currentPricePeriod]}
        description={plan.description}
        onClickOnConnect={() => connectProduct('evolutionMlInference', plan)}
        currentPricePeriod={'priceHourNds'}
      />
    );
  });

  return (
    <TariffSwiper
      slides={[
        ...slides,
        <CalcCard
          key="card-calculator"
          planIndex={slides.length}
          product="evolutionMlInferenceModelSharedGpu"
        />,
      ]}
      isProduct={isProductPage}
    />
  );
}

export { MlInferenceModelSharedGpu };

import { ButtonTonal } from '@snack-uikit/button';
import { Card } from '@snack-uikit/card';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import { useRouter } from 'next/navigation';
import { useTariffPlansState } from 'src/components/TariffPlans/tariff-plans-context';
import { TariffsProduct } from 'src/components/TariffPlans/types';
import { useAnalytics } from 'src/hooks/useAnalytics';

import s from './CalcCard.module.scss';

type CalcCardProps = {
  product: TariffsProduct;
  planIndex: number;
};

export function CalcCard({ product, planIndex }: CalcCardProps) {
  const router = useRouter();
  const state = useTariffPlansState();

  const isActive = state.activePlanIndex === planIndex;

  const cardClassName = cn(s.card, {
    [s.vm]: product === 'evolutionCloudServer',
    [s.s3]: product === 'evolutionStorageS3',
    [s.kuber]: product === 'evolutionKubernetes',
    [s.cardHovered]: isActive,
  });

  function getAnalyticId() {
    switch (product) {
      case 'evolutionCloudServer':
        return 'evolution-cloud-server-card-calculator';
      case 'evolutionKubernetes':
      case 'evolutionKubernetesLandingPage':
        return 'evolution-kuber-card-calculator';
      case 'evolutionStorageS3':
        return 'evolution-storage-card-calculator';
      case 'evolutionBareMetal':
        return 'evolution-bare-metal-card-calculator';
      case 'evolutionCloudServerGpu':
        return 'evolution-cloud-server-gpu-card-calculator';
      case 'evolutionPostgreSql':
        return 'evolution-postgre-sql-card-calculator';
      case 'evolutionContainerApps':
        return 'evolution-container-apps-card-calculator';
      case 'evolutionMlInferenceInstanceTypeGpu':
      case 'evolutionMlInferenceModelSharedGpu':
        return 'evolution-ml-inference';
      default:
        return 'unknown-analytics-id';
    }
  }

  const analyticId = getAnalyticId();
  const { clickAnalytics } = useAnalytics();

  const parseText = (str: string) => {
    return str.replace(/&nbsp;/g, '\u00A0');
  };

  function getCardTitle() {
    switch (product) {
      case 'evolutionCloudServer':
        return parseText(
          'Соберите свою виртуальную машину в&nbsp;калькуляторе',
        );
      case 'evolutionKubernetes':
        return parseText('Соберите свой Kubernetes в&nbsp;калькуляторе');
      case 'evolutionStorageS3':
        return parseText(
          'Соберите свое Объектное хранилище S3 в&nbsp;калькуляторе',
        );
      case 'evolutionBareMetal':
        return parseText(
          'Выберите свою конфигурацию Bare&nbsp;Metal в&nbsp;калькуляторе',
        );
      case 'evolutionCloudServerGpu':
        return parseText(
          'Выберите свою виртуальную машину GPU в&nbsp;калькуляторе',
        );
      case 'evolutionPostgreSql':
        return parseText(
          'Соберите свой кластер баз данных в&nbsp;калькуляторе',
        );
      case 'evolutionMlInferenceInstanceTypeGpu':
      case 'evolutionMlInferenceModelSharedGpu':
        return parseText('Соберите свою конфигурацию для запуска инференса');
      default:
        return parseText('Соберите свою конфигурацию в&nbsp;калькуляторе');
    }
  }

  const handleButtonClick = (product: TariffsProduct) => {
    clickAnalytics({
      action: 'click',
      clickZone: 'body',
      clickElement: 'button',
      clickContent: 'В калькулятор',
      uniqueId: analyticId,
      transitionType: 'inside-link',
    });
    router.push('/calculator' + '?product=' + product);
  };

  return (
    <Card className={cardClassName}>
      <div className={s.content}>
        <Typography.SansTitleL className={s.title}>
          {getCardTitle()}
        </Typography.SansTitleL>
        <ButtonTonal
          size="m"
          className={s.button}
          label="Собрать"
          onClick={() => handleButtonClick(product)}
          appearance="neutral"
        />
      </div>
      <img
        src="/svg/components/Calculator/calculator.png"
        alt="calculator"
        className={s.img}
      />
    </Card>
  );
}

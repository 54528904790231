import { ACTIONS } from 'src/components/TariffPlans/actions';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import { useTariffPlansState } from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoMlInferenceInstanceTypeGpu,
  PlanIdMlInferenceInstanceTypeGpu,
  PlansSwiperProps,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { TariffSwiper } from '../../components/TariffSwiper';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';

interface PlanCardProps {
  planId: PlanIdMlInferenceInstanceTypeGpu;
  price: number;
  description: string;
  plan: PlanEvoMlInferenceInstanceTypeGpu;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
}

function MlInferenceInstanceTypeGpuPlanCard({
  planId,
  price,
  plan,
  description,
  onClickOnConnect,
  currentPricePeriod,
}: PlanCardProps) {
  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType:
      ACTIONS.UPD_PLAN_EVOLUTION_ML_INFERENCE_INSTANCE_TYPE_GPU_PRICE,
    statusActionType:
      ACTIONS.UPD_PLAN_EVOLUTION_ML_INFERENCE_INSTANCE_TYPE_GPU_STATUS,
    productType: 'evolutionMlInference',
    nestedProductType: 'evolutionMlInferenceInstanceTypeGpu',
  });

  const characteristics = [
    {
      title: 'Тип инстанса',
      value: `${plan.planId}`,
    },
    {
      title: 'Запросы к ML-моделям',
      value: `${plan.data.mlInferenceRequestsCount} млн запросов`,
    },
    {
      title: 'Кэш ML-моделей',
      value: `${plan.data.mlInferenceCacheVolume} ГБ`,
    },
    {
      title: 'Время работы',
      value: `${plan.data.mlInferenceWorkingHours} час`,
    },
  ];

  return (
    <PlanCard
      productType="evolutionMlInference"
      planStatus={planState?.planStatus}
      description={description}
      currentPricePeriod={currentPricePeriod}
      price={price}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
    />
  );
}

function MlInferenceInstanceTypeGpu({ isProductPage }: PlansSwiperProps) {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[
    state.tariffPlansType
  ].evolutionMlInferenceInstanceTypeGpu.map((plan) => {
    return (
      <MlInferenceInstanceTypeGpuPlanCard
        key={plan.planId}
        planId={plan.planId}
        price={plan.price[state.currentPricePeriod]}
        description={plan.description}
        plan={plan}
        onClickOnConnect={() => connectProduct('evolutionMlInference', plan)}
        currentPricePeriod={'priceHourNds'}
      />
    );
  });

  return (
    <TariffSwiper
      slides={[
        ...slides,
        <CalcCard
          key="card-calculator"
          planIndex={slides.length}
          product="evolutionMlInferenceInstanceTypeGpu"
        />,
      ]}
      isProduct={isProductPage}
    />
  );
}

export { MlInferenceInstanceTypeGpu };

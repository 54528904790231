import { ProductTypeConnect } from 'src/components/Calculator/types';
import { transformProductDataToConsole } from 'src/components/Calculator/utils/transformProductDataToConsole';
import {
  CONSOLE_DEV_STATIC_PAGE_LOGIN_DESTINATION_URL,
  CONSOLE_STATIC_PAGE_LOGIN_DESTINATION_URL,
} from 'src/components/TariffPlans/initialData';
import {
  PlanEvoBareMetal,
  PlanEvoCloudServer,
  PlanEvoContainerApps,
  PlanEvoKuber,
  PlanEvoKuberLandingPage,
  PlanEvoMlInferenceInstanceTypeGpu,
  PlanEvoMlInferenceModelSharedGpu,
  PlanEvoPostgreSql,
  PlanEvoStorage,
} from 'src/components/TariffPlans/types';
import { consoleUrl } from 'src/global';
import { checkGetParamsFromUrl, useAnalytics } from 'src/hooks/useAnalytics';
import { getOriginalUTM } from 'src/utils/getOriginalUTM';

function productTypeNameForYm(productType: ProductTypeConnect) {
  switch (productType) {
    case 'evolutionCloudServer':
      return 'Evo-cloud-server';
    case 'evolutionCloudServerFreeTier':
      return 'Evo-cloud-server-free-tier';
    case 'evolutionKubernetes':
      return 'Evo-kubernetes';
    case 'evolutionStorageS3':
      return 'Evo-storageS3';
    case 'evolutionStorageS3FreeTier':
      return 'Evo-storageS3-free-tier';
    case 'evolutionContainerApps':
      return 'Evo-container-apps';
    default:
      return '';
  }
}

function allClicksProductTypeNameForYm(productType: ProductTypeConnect) {
  switch (productType) {
    case 'evolutionCloudServer':
      return 'evocompute';
    case 'evolutionCloudServerFreeTier':
      return 'evocomputefreetier';
    case 'evolutionCloudServerGpu':
      return 'evocomputegpu';
    case 'evolutionKubernetes':
      return 'evokubernetes';
    case 'evolutionStorageS3':
      return 'evostorages3';
    case 'evolutionStorageS3FreeTier':
      return 'evostorages3fteetier';
    case 'evolutionContainerApps':
      return 'evocontainerapps';
    default:
      return '';
  }
}

function linkForYm(productType: ProductTypeConnect): string {
  const linksMap = {
    evolutionCloudServer: `${consoleUrl}/spa/svp/virtual-machines/create`,
    evolutionCloudServerFreeTier: `${consoleUrl}/spa/svp/virtual-machines/create?free-tier=true`,
    evolutionKubernetes: `${consoleUrl}/spa/svp/managed-kubernetes/clusters/create`,
    evolutionStorageS3: `${consoleUrl}/spa/svp/s3-storage/buckets/create`,
    evolutionStorageS3FreeTier: `${consoleUrl}/spa/svp/s3-storage/buckets/create`,
    evolutionCloudServerGpu: `${consoleUrl}/spa/svp/virtual-machines/create?gpu=true`, // TODO: ссылка возможно не правильная
    evolutionPostgreSql: `${consoleUrl}/spa/postgre/cluster-create`,
    evolutionBareMetal: `${consoleUrl}/spa/svp/bare-metal/buckets/create`,
    evolutionContainerApps: `${consoleUrl}/spa/serverless-containers/containers/create/new`,
    evolutionMlInference: `${consoleUrl}`,
  };

  return linksMap[productType] as string;
}

function getTariffPlanFreeTierProduct(productType: ProductTypeConnect) {
  switch (productType) {
    case 'evolutionCloudServerFreeTier':
      return 'Evolution-Compute';
    case 'evolutionStorageS3FreeTier':
      return 'Evolution-Object-Storage';
    default:
      return null;
  }
}

function getProductPageMetrics(pathname: string) {
  switch (pathname) {
    case '/':
      return 'Main';
    case '/evolution':
      return 'Evolution-Main';
    default:
      return 'Product';
  }
}

const ObjectUTMToSend = {
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_term: '',
  retain_url: '',
  redirect_uri: '',
  zoneclick: '',
  nameelement: '',
};

const checkSpace = () => {
  const url = window.location.href;
  const condition =
    url.indexOf('autotest') !== -1 ||
    url.indexOf('shadow') !== -1 ||
    url.indexOf('cloudtest') !== -1;

  if (
    condition ||
    process.env.NEXT_PUBLIC_SPACE === 'develop' ||
    process.env.NEXT_PUBLIC_SPACE === 'stage'
  ) {
    return 'dev';
  }

  return 'prod';
};

const getCreateVmUrl = () => {
  if (checkSpace() === 'dev') {
    return CONSOLE_DEV_STATIC_PAGE_LOGIN_DESTINATION_URL;
  }
  return CONSOLE_STATIC_PAGE_LOGIN_DESTINATION_URL;
};

type Plan =
  | PlanEvoCloudServer
  | PlanEvoKuber
  | PlanEvoStorage
  | PlanEvoPostgreSql
  | PlanEvoKuberLandingPage
  | PlanEvoBareMetal
  | PlanEvoContainerApps
  | PlanEvoMlInferenceInstanceTypeGpu
  | PlanEvoMlInferenceModelSharedGpu;
/**
 * Хук возвращает функцию connectProduct при помощи которой мы отправляем
 * данные активного продукта в консоль
 */
function useSendConfigToConsole() {
  const { sendAnalytics } = useAnalytics();

  const connectProduct = (productType: ProductTypeConnect, plan: Plan) => {
    const config = plan.data;

    const decodedOriginalUTM = getOriginalUTM();

    const objectUTM: Partial<Record<string, string>> = {
      zoneclick: 'body',
      nameelement: 'Подключить',
      retain_url: location.toString().replace(location.search, ''),
    };

    decodedOriginalUTM?.split('&')?.forEach((item) => {
      const utmArr = item.split('=');
      objectUTM[utmArr[0]] = utmArr[1];
    });

    let utmParams = '';

    Object.keys(objectUTM).forEach((key) => {
      if (key in ObjectUTMToSend) {
        utmParams += `&${key}=${objectUTM[key]}`;
      }
    });

    const configToConnect = transformProductDataToConsole({
      productType: productType,
      productData: config,
    });

    const stringifiedConfig = btoa(JSON.stringify(configToConnect));

    const totalUrl = `${getCreateVmUrl()}?config=${stringifiedConfig}${utmParams}`;

    // проверка на максимальную длину - что бы url не превысел допустимый максимум
    if (totalUrl.length > 2083) {
      // eslint-disable-next-line no-console
      console.error('длина url');
      return;
    }

    const productNameForYm = productTypeNameForYm(productType);
    const allClicksRetainUrl = checkGetParamsFromUrl(window.location.href);
    const allClicksProductLinkForYm = linkForYm(productType);
    const allClicksProductNameForYm =
      allClicksProductTypeNameForYm(productType);

    const ymData = {
      'Tariff-plan-connect': {
        [productNameForYm]: {
          [window.location.href.split('?')[0]]: {
            'Tariff-plan': 'tariff-plan-' + plan.planId,
          },
        },
      },
    };
    sendAnalytics({}, ymData, 'Tariff-plan-connect');

    const allCLickYmData = {
      'All-clicks': {
        [allClicksRetainUrl]: {
          [allClicksProductLinkForYm]: {
            ['body']: {
              [`calculator-${allClicksProductNameForYm}-${plan.planId}`]: {
                ['Подключить']: {
                  ['tariff-connect-button']: 'outside-link',
                },
              },
            },
          },
        },
      },
    };
    sendAnalytics({}, allCLickYmData, 'All-clicks');

    const product = getTariffPlanFreeTierProduct(productType);
    const page = getProductPageMetrics(window.location.pathname);

    if (product) {
      const tariffPlanConnectFreeTier = {
        'Tariff-plan-connect-free-tier': {
          [product]: page,
        },
      };
      sendAnalytics(
        {},
        tariffPlanConnectFreeTier,
        `Tariff-plan-connect-free-tier-${product}-${page}`,
      );
    }

    if (productType === 'evolutionMlInference') {
      window.open(`${getCreateVmUrl()}?${utmParams}`, '_self');
      return;
    }

    window.open(totalUrl, '_self');
  };

  return { connectProduct };
}

export { useSendConfigToConsole };

import { ACTIONS_TYPE } from '../actions';
import {
  DeepPartial,
  EvsDiskAdditional,
  Price,
  ProductBase,
  ProductCloudServerUpdAdditionalDisk,
  ProductDataBase,
} from './base';

export type EvoProductPlan = 'freeTier' | 'default';

export type GuaranteedPartValues = '10' | '30' | '100';
export type EvolutionCloudServerRamAmountValues =
  | '1'
  | '2'
  | '4'
  | '8'
  | '16'
  | '24'
  | '32'
  | '48'
  | '64'
  | '128'
  | '256'
  | '512'
  | '1024';
export type EvolutionCloudServerVcpuCoreCountValues =
  | '1'
  | '2'
  | '4'
  | '8'
  | '16'
  | '24'
  | '32'
  | '64';
export type OsSpecific =
  | 'CentOS 7'
  | 'CentOS 7 kernel 5.4'
  | 'CentOS 7 kernel 5.4 v2'
  | 'CentOS 8'
  | 'CentOS 9'
  | 'Debian 12'
  | 'Fedora 38'
  | 'Ubuntu 18.04'
  | 'Ubuntu 20.04'
  | 'Ubuntu 22.04'
  | 'openSUSE 15.2';

export type EvolutionStorageS3DeploymentZone = '1AZ' | 'MultiAZ';

export type EvolutionStorageS3Types = 'standart' | 'cold' | 'ice' | 'single';
export type EvolutionStorageS3FreeTierTypes = 'standart' | 'cold' | 'ice';

export type EvolutionKubernetesNodeConfigValues =
  | '2 4'
  | '4 8'
  | '8 16'
  | '16 32';
export type EvolutionKubernetesMasterNodeCountValues = '1' | '3' | '5';
export type EvolutionKubernetesVcpuCountValues =
  | '1'
  | '2'
  | '4'
  | '8'
  | '16'
  | '24'
  | '32'
  | '64';
export type EvolutionKubernetesRamAmountValues =
  | '1'
  | '2'
  | '4'
  | '8'
  | '16'
  | '32'
  | '48'
  | '64'
  | '128'
  | '256';

export type EvolutionPostgreSqlClusterType = 'single';
export type EvolutionPostgreSqlDeploymentMode = 'business' | 'standart';
export type EvolutionPostgreSqlVcpu =
  | '0.5'
  | '1'
  | '2'
  | '4'
  | '8'
  | '16'
  | '24'
  | '32'
  | '64';
export type EvolutionPostgreSqlRam =
  | '1'
  | '2'
  | '4'
  | '8'
  | '16'
  | '32'
  | '48'
  | '64'
  | '128'
  | '256';

export type BareMetalSpecification =
  | '2 x Intel Broadwell EP Xeon E5-2699 v4 22 ядра 2.2ГГц; RAM: 64ГБ; SSD: 800ГБ SATA'
  | '2 x Intel Broadwell EP Xeon E5-2699 v4 22 ядра 2.2ГГц; RAM: 64ГБ; HDD: 2 x 900ГБ SAS'
  | '2 x Intel Haswell EP Xeon E5-2690 v3 12 ядер 2.6ГГц; RAM: 64ГБ; HDD: 4 x 2ТБ SATA'
  | '2 x Intel Haswell EP Xeon E5-2690 v3 12 ядер 2.6ГГц; RAM: 128ГБ; HDD: 4 x 2ТБ SATA'
  | '2 x Intel Skylake-SP Xeon Gold 6161 22 ядра 2.2ГГц; RAM: 64ГБ; SSD: 4 x 800ГБ SAS';

export type OsName = 'Ubuntu' | 'Oracle Linux';
export type OsVersion = OsVersionUbuntu | OsVersionOracleLinux;
type OsVersionUbuntu = '20.04' | '22.04' | '24.04';
type OsVersionOracleLinux = '9.3';

// EVO

// evolutionCloudServer

interface ProductEvolutionCloudServer extends ProductBase {
  data: ProductEvolutionCloudServerData;
  price: Price;
}

export interface ProductEvolutionCloudServerData extends ProductDataBase {
  guaranteedPart: GuaranteedPartValues;
  vCpuCoreCount: EvolutionCloudServerVcpuCoreCountValues;
  ramAmount: EvolutionCloudServerRamAmountValues;
  os: OsSpecific;
  evs: {
    additionalDisks: EvsDiskAdditional[];
    systemDisk: {
      specification: 'SSD';
      diskSpace: number;
    };
  };
  networkIsNeeded: boolean;
  network: {
    ipQuantity: number;
    bindingIpAddressesQuantity: number;
    privateGatewayQuantity: number;
    publicGatewayQuantity: number;
  };
}

type UPD_PRODUCT_EVOLUTION_CLOUD_SERVER_INPUTS = {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVOLUTION_CLOUD_SERVER_INPUTS'];
  payload: DeepPartial<ProductEvolutionCloudServerData>;
};

interface UPD_PRODUCT_EVO_CLOUD_SERVER_ADDITIONAL_DISK {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVO_CLOUD_SERVER_ADDITIONAL_DISK'];
  payload: ProductCloudServerUpdAdditionalDisk;
}

interface ADD_PRODUCT_EVO_CLOUD_SERVER_ADDITIONAL_DISK {
  type: ACTIONS_TYPE['EVO']['ADD_PRODUCT_EVO_CLOUD_SERVER_ADDITIONAL_DISK'];
}

interface DELETE_PRODUCT_EVO_CLOUD_SERVER_ADDITIONAL_DISK {
  type: ACTIONS_TYPE['EVO']['DELETE_PRODUCT_EVO_CLOUD_SERVER_ADDITIONAL_DISK'];
  payload: number;
}

// evolutionCloudServerFreeTier

interface ProductEvolutionCloudServerFreeTier extends ProductBase {
  data: ProductEvolutionCloudServerFreeTierData;
  price: Price;
}

export interface ProductEvolutionCloudServerFreeTierData
  extends ProductDataBase {
  guaranteedPart: GuaranteedPartValues;
  vCpuCoreCount: EvolutionCloudServerVcpuCoreCountValues;
  ramAmount: EvolutionCloudServerRamAmountValues;
  os: OsSpecific;
  evs: {
    additionalDisksIsNeeded: boolean;
    additionalDisks: EvsDiskAdditional[];
    systemDisk: {
      specification: 'SSD';
      diskSpace: number;
    };
  };
  networkIsNeeded: boolean;
  network: {
    ipQuantity: number;
    bindingIpAddressesQuantity: number;
    privateGatewayQuantity: number;
    publicGatewayQuantity: number;
  };
}

type UPD_PRODUCT_EVOLUTION_CLOUD_SERVER_FREE_TIER_INPUTS = {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVOLUTION_CLOUD_SERVER_FREE_TIER_INPUTS'];
  payload: DeepPartial<ProductEvolutionCloudServerFreeTierData>;
};

type UPD_PRODUCT_EVO_CLOUD_SERVER_FREE_TIER_ADDITIONAL_DISK = {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVO_CLOUD_SERVER_FREE_TIER_ADDITIONAL_DISK'];
  payload: ProductCloudServerUpdAdditionalDisk;
};

type ADD_PRODUCT_EVO_CLOUD_SERVER_FREE_TIER_ADDITIONAL_DISK = {
  type: ACTIONS_TYPE['EVO']['ADD_PRODUCT_EVO_CLOUD_SERVER_FREE_TIER_ADDITIONAL_DISK'];
};

type DELETE_PRODUCT_EVO_CLOUD_SERVER_FREE_TIER_ADDITIONAL_DISK = {
  type: ACTIONS_TYPE['EVO']['DELETE_PRODUCT_EVO_CLOUD_SERVER_FREE_TIER_ADDITIONAL_DISK'];
  payload: number;
};

// evolutionStorageS3

interface ProductEvolutionStorageS3 extends ProductBase {
  data: ProductEvolutionStorageS3Data;
  price: Price;
}

interface ProductEvolutionStorageS3Data extends ProductDataBase {
  // TODO сделан опциональным, но по факту обязательный, нужно привести типы в порядок в тарифах, тк фри тир и не фри тир отличаются по полям
  deploymentZone?: EvolutionStorageS3DeploymentZone;
  storageType: EvolutionStorageS3Types;
  storageSize: number;
  apiPut: number;
  apiList: number;
  apiPost: number;
  apiGet: number;
  apiHead: number;
  outgoingTraffic: number;
}

type UPD_PRODUCT_EVOLUTION_STORAGE_S3_INPUTS = {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVOLUTION_STORAGE_S3_INPUTS'];
  payload: DeepPartial<ProductEvolutionStorageS3Data>;
};

// evolutionStorageS3FreeTier

interface ProductEvolutionStorageS3FreeTier extends ProductBase {
  data: ProductEvolutionStorageS3FreeTierData;
  price: Price;
}

interface ProductEvolutionStorageS3FreeTierData extends ProductDataBase {
  storageType: EvolutionStorageS3FreeTierTypes;
  storageSize: number;
  apiPut: number;
  apiList: number;
  apiPost: number;
  apiGet: number;
  apiHead: number;
  outgoingTraffic: number;
}

type UPD_PRODUCT_EVOLUTION_STORAGE_S3_FREE_TIER_INPUTS = {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVOLUTION_STORAGE_S3_FREE_TIER_INPUTS'];
  payload: DeepPartial<ProductEvolutionStorageS3FreeTierData>;
};

// ProductEvolutionKubernetes

interface ProductEvolutionKubernetes extends ProductBase {
  data: ProductEvolutionKubernetesData;
  price: Price;
}

interface ProductEvolutionKubernetesData extends ProductDataBase {
  masterNode: {
    nodeConfig: EvolutionKubernetesNodeConfigValues;
    nodeCount: EvolutionKubernetesMasterNodeCountValues;
  };
  workerNode: {
    guaranteedPart: GuaranteedPartValues;
    vCpuCount: EvolutionKubernetesVcpuCountValues;
    ramAmount: EvolutionKubernetesRamAmountValues;
    nodeCount: number;
    diskSize: number;
  };
  workerNodeNeeded: boolean;
  bindingPublickIpAddress: boolean;
}

type UPD_PRODUCT_EVOLUTION_KUBERNETES_INPUTS = {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVOLUTION_KUBERNETES_INPUTS'];
  payload: DeepPartial<ProductEvolutionKubernetesData>;
};

// ProductEvolutionPostgreSql

interface ProductEvolutionPostgreSql extends ProductBase {
  data: ProductEvolutionPostgreSqlData;
  price: Price;
}

interface ProductEvolutionPostgreSqlData extends ProductDataBase {
  clusterType: EvolutionPostgreSqlClusterType;
  deploymentMode: EvolutionPostgreSqlDeploymentMode;
  flavorConfig: {
    vCpu: EvolutionPostgreSqlVcpu;
    ram: EvolutionPostgreSqlRam;
  };
  systemDisk: {
    specification: 'SSD';
    diskSpace: number;
  };
  bindingPublickIpAddress: boolean;
}

type UPD_PRODUCT_EVOLUTION_POSTGRE_SQL_INPUTS = {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVOLUTION_POSTGRE_SQL_INPUTS'];
  payload: DeepPartial<ProductEvolutionPostgreSqlData>;
};

// ProductEvolutionBareMetal
interface ProductEvolutionBareMetal extends ProductBase {
  data: ProductEvolutionBareMetalData;
  price: Price;
}

interface ProductEvolutionBareMetalData extends ProductDataBase {
  nameFlavour: BareMetalSpecification;
  osName: OsName;
  osVersion: OsVersion;
  bindingPublickIpAddress: true;
}

type UPD_PRODUCT_EVOLUTION_BARE_METAL_INPUTS = {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVOLUTION_BARE_METAL_INPUTS'];
  payload: DeepPartial<ProductEvolutionBareMetalData>;
};

// evolutionCloudServerGpu

interface ProductEvolutionCloudServerGpu extends ProductBase {
  data: ProductEvolutionCloudServerGpuData;
  price: Price;
}

type GpuCount = '1' | '2' | '4' | '8' | '16';
type gpuType = 'GPU NVIDIA® Tesla® V100';

export interface ProductEvolutionCloudServerGpuData extends ProductDataBase {
  vCpuCoreCount: EvolutionCloudServerVcpuCoreCountValues;
  ramAmount: EvolutionCloudServerRamAmountValues;
  os: OsSpecific;
  gpuType: gpuType;
  gpuCount: GpuCount;
  evs: {
    additionalDisks: EvsDiskAdditional[];
    systemDisk: {
      specification: 'SSD';
      diskSpace: number;
    };
  };
  networkIsNeeded: boolean;
  network: {
    ipQuantity: number;
    bindingIpAddressesQuantity: number;
    privateGatewayQuantity: number;
    publicGatewayQuantity: number;
  };
}

interface UPD_PRODUCT_EVOLUTION_CLOUD_SERVER_GPU_INPUTS {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVOLUTION_CLOUD_SERVER_GPU_INPUTS'];
  payload: DeepPartial<ProductEvolutionCloudServerGpuData>;
}

interface UPD_PRODUCT_EVO_CLOUD_SERVER_GPU_ADDITIONAL_DISK {
  type: ACTIONS_TYPE['EVO']['UPD_PRODUCT_EVO_CLOUD_SERVER_GPU_ADDITIONAL_DISK'];
  payload: ProductCloudServerUpdAdditionalDisk;
}

interface ADD_PRODUCT_EVO_CLOUD_SERVER_GPU_ADDITIONAL_DISK {
  type: ACTIONS_TYPE['EVO']['ADD_PRODUCT_EVO_CLOUD_SERVER_GPU_ADDITIONAL_DISK'];
}

interface DELETE_PRODUCT_EVO_CLOUD_SERVER_GPU_ADDITIONAL_DISK {
  type: ACTIONS_TYPE['EVO']['DELETE_PRODUCT_EVO_CLOUD_SERVER_GPU_ADDITIONAL_DISK'];
  payload: number;
}

// evolutionContainerApps

type EvolutionContainerAppsConfigType =
  | '0.1 256'
  | '0.2 512'
  | '0.3 768'
  | '0.5 1024';

export enum WorkingHoursSpecification {
  Hour = 'hour',
  Day = 'day',
  Month = 'month',
}

interface ProductEvolutionContainerApps extends ProductBase {
  data: ProductEvolutionContainerAppsData;
  price: Price;
}

export interface ProductEvolutionContainerAppsData extends ProductDataBase {
  config: EvolutionContainerAppsConfigType;
  workingHours: number;
  workingHoursSpecification: WorkingHoursSpecification;
}

// evolutionMlInference

interface ProductEvolutionMlInference extends ProductBase {
  data: ProductEvolutionMlInferenceData;
  price: Price;
}

export type MlInferenceLaunchMode = 'model-shared-gpu' | 'instance-type-gpu';

export type MlInferenceConfigurationType = 'shared-ready' | 'custom';

export type MlInferenceConfiguration =
  | 'qwen-qwen-2.5'
  | 'google'
  | 'baai'
  | 'black-forest'
  | 'qwen-qwq'
  | 'deepseek';

export type MlInferenceGpu =
  | 'nvidia-h100-pcie'
  | 'nvidia-h100-nvlink'
  | 'nvidia-a100-pcie'
  | 'nvidia-v100-nvlink';

export type MlInferenceGpuInstanceType =
  | 'nvidia-h100-pcie-1gpu'
  | 'nvidia-h100-pcie-2gpu'
  | 'nvidia-h100-pcie-4gpu'
  | 'nvidia-h100-pcie-6gpu'
  | 'nvidia-h100-pcie-8gpu'
  | 'nvidia-h100-nvlink-1gpu'
  | 'nvidia-h100-nvlink-2gpu'
  | 'nvidia-h100-nvlink-4gpu'
  | 'nvidia-h100-nvlink-6gpu'
  | 'nvidia-h100-nvlink-8gpu'
  | 'nvidia-a100-pcie-1gpu'
  | 'nvidia-a100-pcie-2gpu'
  | 'nvidia-a100-pcie-4gpu'
  | 'nvidia-a100-pcie-6gpu'
  | 'nvidia-a100-pcie-8gpu'
  | 'nvidia-v100-nvlink-1gpu'
  | 'nvidia-v100-nvlink-2gpu'
  | 'nvidia-v100-nvlink-4gpu'
  | 'nvidia-v100-nvlink-8gpu'
  | 'nvidia-v100-nvlink-16gpu';

export interface ProductEvolutionMlInferenceData extends ProductDataBase {
  mlInferenceLaunchMode: MlInferenceLaunchMode;
  mlInferenceConfigurationType: MlInferenceConfigurationType;
  mlInferenceConfiguration: MlInferenceConfiguration;
  mlInferenceGpu: MlInferenceGpu;
  mlInferenceGpuMemory: number; // от 4 до 80 ГБ с шагом 4
  mlInferenceGpuInstanceType: MlInferenceGpuInstanceType;
  mlInferenceRequestsCount: number; // от 1 до 9999999999
  mlInferenceCacheVolume: number; // от 1 до 9999999999 ГБ
  mlInferenceWorkingHours: number; // от 1 до maxWorkingHours
  mlInferenceWorkingHoursSpecification: WorkingHoursSpecification;
  productQuantity: number;
}

export type ProductMapEvo = {
  evolutionCloudServer: ProductEvolutionCloudServer;
  evolutionCloudServerFreeTier: ProductEvolutionCloudServerFreeTier;
  evolutionStorageS3: ProductEvolutionStorageS3;
  evolutionStorageS3FreeTier: ProductEvolutionStorageS3FreeTier;
  evolutionKubernetes: ProductEvolutionKubernetes;
  evolutionPostgreSql: ProductEvolutionPostgreSql;
  evolutionBareMetal: ProductEvolutionBareMetal;
  evolutionCloudServerGpu: ProductEvolutionCloudServerGpu;
  evolutionContainerApps: ProductEvolutionContainerApps;
  evolutionMlInference: ProductEvolutionMlInference;
};

export type ActionEvo =
  | UPD_PRODUCT_EVOLUTION_CLOUD_SERVER_INPUTS
  | UPD_PRODUCT_EVO_CLOUD_SERVER_ADDITIONAL_DISK
  | ADD_PRODUCT_EVO_CLOUD_SERVER_ADDITIONAL_DISK
  | DELETE_PRODUCT_EVO_CLOUD_SERVER_ADDITIONAL_DISK
  //
  | UPD_PRODUCT_EVOLUTION_STORAGE_S3_INPUTS
  //
  | UPD_PRODUCT_EVOLUTION_STORAGE_S3_FREE_TIER_INPUTS
  //
  | UPD_PRODUCT_EVOLUTION_KUBERNETES_INPUTS
  //
  | UPD_PRODUCT_EVOLUTION_POSTGRE_SQL_INPUTS
  //
  | UPD_PRODUCT_EVOLUTION_CLOUD_SERVER_FREE_TIER_INPUTS
  | UPD_PRODUCT_EVO_CLOUD_SERVER_FREE_TIER_ADDITIONAL_DISK
  | ADD_PRODUCT_EVO_CLOUD_SERVER_FREE_TIER_ADDITIONAL_DISK
  | DELETE_PRODUCT_EVO_CLOUD_SERVER_FREE_TIER_ADDITIONAL_DISK
  //
  | UPD_PRODUCT_EVOLUTION_BARE_METAL_INPUTS
  //
  | UPD_PRODUCT_EVOLUTION_CLOUD_SERVER_GPU_INPUTS
  | UPD_PRODUCT_EVO_CLOUD_SERVER_GPU_ADDITIONAL_DISK
  | ADD_PRODUCT_EVO_CLOUD_SERVER_GPU_ADDITIONAL_DISK
  | DELETE_PRODUCT_EVO_CLOUD_SERVER_GPU_ADDITIONAL_DISK;

import { useState } from 'react';
import { PlansSwiperProps } from 'src/components/TariffPlans/types';

import {
  MlInferenceChanger,
  TabValue,
} from '../../components/MlInferenceChanger';
import { MlInferenceInstanceTypeGpu } from './MlInferenceInstanceTypeGpu';
import { MlInferenceModelSharedGpu } from './MlInferenceModelSharedGpu';

function EvoMlInferencePlanSwiper({ isProductPage }: PlansSwiperProps) {
  const [activeTab, setActiveTab] = useState(TabValue.ModelSharedGpu);

  const onTabClick = (value: TabValue) => setActiveTab(value);

  return (
    <>
      <MlInferenceChanger activeTab={activeTab} onTabClick={onTabClick} />
      {activeTab === TabValue.ModelSharedGpu && (
        <MlInferenceModelSharedGpu isProductPage={isProductPage} />
      )}
      {activeTab === TabValue.InstanceTypeGpu && (
        <MlInferenceInstanceTypeGpu isProductPage={isProductPage} />
      )}
    </>
  );
}

export { EvoMlInferencePlanSwiper };
